<template>
<v-dialog v-model="reportsDialog" persistent  :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true">
    <v-card max-width="1900" min-width="600">
        <v-system-bar dark>
            <v-spacer></v-spacer>
            <v-icon v-if="$route.name != 'empty'" @click="setReportsDialog(false)">mdi-close</v-icon>
        </v-system-bar>

        <v-card-title :class="$vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-2`"><span v-if="reportData.options">{{reportData.options.title}}</span></v-card-title>
        <v-card-text :class="$vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-2`">
            <div class="text-center pa-10" v-show="isLoading">
                <v-progress-circular indeterminate color="grey"></v-progress-circular>
            </div>
            <v-simple-table dense fill-height v-if="!isLoading">
                <template v-slot:default>
                    <thead>
                        <tr v-if="reportData.options">
                            <th class="text-center px-1 px-sm-4 orange lighten-4" colspan="6">
                                Результат
                            </th>
                            <th v-if="reportData.options.fromFile" class="text-center px-1 px-sm-4 blue lighten-4" :colspan="3+reportData.options.shops.length">
                                Завантажено з файлу
                            </th>
                            <th class="text-center px-1 px-sm-4 orange lighten-4" :colspan="2+reportData.options.shops.length">
                                Результат
                            </th>

                        </tr>
                        <tr v-if="reportData.options">
                            <th class="text-left px-1 px-sm-4 orange lighten-5" width="1%">
                                Вмк.
                            </th>
                            <th class="text-center px-1 px-sm-4 orange lighten-5" width="1%">
                                #
                            </th>
                            <th class="text-center px-1 px-sm-4 orange lighten-5" width="5%">
                                Код товару
                            </th>
                            <th class="text-left px-1 px-sm-4 orange lighten-5" width="20%">
                                Назва
                            </th>
                            <th class="text-center px-1 px-sm-4 orange lighten-5" width="5%">
                                Дата
                            </th>
                            <th class="text-center px-1 px-sm-4 orange lighten-5" width="1%">
                                У пак.
                            </th>
                            <th v-if="reportData.options.fromFile" class="text-center px-1 px-sm-4 blue lighten-5" width="1%">
                                {{reportData.options && reportData.options.selType == 'typhoon_id' ? `Код ЮК` : (reportData.options.selType == 'imei' ? 'IMEI/СН' : 'Артикул')}}
                            </th>
                            <th v-if="reportData.options.fromFile" class="text-left px-1 px-sm-4 blue lighten-5" width="1%">
                                Назва
                            </th>
                            <th v-if="reportData.options.fromFile" class="text-center px-1 px-sm-4 blue lighten-5" width="5%">
                                Дата
                            </th>
                            <th v-if="reportData.options.fromFile" class="text-center px-1 px-sm-4 blue lighten-5" width="5%" v-for="shopId in reportData.options.shops" :key="shopId">
                                Кіль-ть<br>
                                {{shops[shopId]}}
                            </th>
                            <th class="text-center px-1 px-sm-4 orange lighten-5" width="3%" v-for="shopId in reportData.options.shops" :key="shopId+'_'">
                                Кіль-ть<br>
                                {{shops[shopId]}}
                            </th>
                            <th class="text-center px-1 px-sm-4 orange lighten-5" width="7%">
                                <v-icon color="primary">mdi-alert-circle</v-icon>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="reportData.recognizedData && !isLoading">
                        <tr v-for="(item, idx) in reportData.recognizedData" :key="idx" :class="item.error && item.error.length ? `primary lighten-5` : ``">
                            <td class="text-left px-1 px-sm-4 caption">
                                <v-checkbox :disabled="(item.error && item.error.length) || isMotivations ? true : false" class="shrink mr-2 mt-0" hide-details v-model="checked[idx]"></v-checkbox>
                            </td>
                            <td class="text-center px-1 px-sm-4 caption">
                                {{idx+1}}
                            </td>
                            <td class="text-center px-1 px-sm-4 caption">{{ item.productData ?  item.productData.typhoon_id : ''}}</td>
                            <td class="text-left px-1 px-sm-4 caption"><span :class="item.productData ?'': 'error--text'">{{ item.productData ? item.productData.name_ukr : '-- Товар не знайдено --' }}</span></td>
                            <td class="text-center px-1 px-sm-4 caption">{{ item.productData && item.productData.sale_date ?  toDate(item.productData.sale_date) : ''}}</td>
                            <td class="text-center px-1 px-sm-4 caption">{{ item.productData ?  item.productData.pack || 1: ''}}</td>
                            <td v-if="reportData.options.fromFile" class="text-center px-1 px-sm-4 caption">
                                {{reportData.options && reportData.options.selType == 'typhoon_id' ? item.line.typhoon_id : (reportData.options.selType == 'imei' ? item.line.imei : item.line.artikul)}}
                            </td>
                            <td v-if="reportData.options.fromFile" class="text-left px-1 px-sm-4 caption">
                                {{item.line.productName}}
                            </td>
                            <td v-if="reportData.options.fromFile" class="text-center px-1 px-sm-4 caption">
                                {{item.line.saleDate}}
                            </td>
                            <td v-if="reportData.options.fromFile" class="text-center px-1 px-sm-4 caption" v-for="shopId in reportData.options.shops" :key="shopId+'_'+idx">
                                {{item.line.shopsQty && item.line.shopsQty[shopId]}}
                            </td>
                            <td class="text-center px-1 px-sm-4 caption" v-for="shopId in reportData.options.shops" :key="shopId+'__'+idx">
                                <v-text-field :class="`my-1 mx-0` + 
                                (reportData.productShop && reportData.productShop[item.productData.typhoon_id] && reportData.productShop[item.productData.typhoon_id][shopId] ? `` : ` grey lighten-4`)" 
                                :disabled="(reportData.productShop && reportData.productShop[item.productData.typhoon_id] && reportData.productShop[item.productData.typhoon_id][shopId] ? false : true)" outlined dense hide-details v-if="shopsQtyModel[shopId]" v-model="shopsQtyModel[shopId][idx]">
                                </v-text-field>
                            </td>
                            <td class="text-left px-1 px-sm-4 caption primary--text">
                                <li v-if="item.error && item.error.find(el => el == 1)"> Код товару ЮК не знайдено!</li>
                                <li v-if="item.error && item.error.find(el => el == 2)"> Артикул не знайдено!</li>
                                <li v-if="item.error && item.error.find(el => el == 3)"> IMEI не знайдено!</li>
                                <li v-if="item.error && item.error.find(el => el == 4)"> Значення колонки «Кіль-ть» невалідне!</li>
                                <li v-if="item.error && item.error.find(el => el == 5)"> Значення колонки «Дата продажу» невалідне!</li>
                                <li v-if="item.error && item.error.find(el => el == 6)"> Дата продажу не відповідає періоду звіта!</li>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card-text>

        <v-card-actions>
            <v-btn text :disabled="isLoading" color="primary" @click="loadData()">Завантажити</v-btn>
            <v-btn text :disabled="isLoading" @click="setReportsDialog(false)">Скасувати</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    data: () => ({
        checked: {},
        shopsQtyModel: {},
        isMotivations: false
    }),
    methods: {
        ...mapActions(['touch', 'submitSales', 'loadSalesReportsList', 'loadStockReportsList', 'submitStock', 'setSnackbar']),
        setReportsDialog(v) {
            this.$store.commit('setReportsDialog', v)
        },
        toDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}-${month}-${year}`;
        },
        loadData() {
            this.touch()
                .then(() => {
                    const rows = []
                    for (let idx in this.checked) {
                        if (this.checked[idx]) { // если выбран чекбокс
                            const row = this.reportData.recognizedData[idx]
                            if (row && row.productData && row.productData.typhoon_id) {
                                if (this.reportData.options && this.reportData.options.shops && this.reportData.options.dateFrom) {
                                    for (let shopId of this.reportData.options.shops) {
                                        rows.push({
                                            typhoon_id: row.productData.typhoon_id,
                                            shop_id: shopId,
                                            qty: this.shopsQtyModel[shopId][idx] || 0,
                                            sale_date: row.productData.sale_date || '',
                                            start_date: this.reportData.options.dateFrom,
                                            finish_date: this.reportData.options.dateTo,
                                        })
                                    }
                                }
                            }
                        }
                    }
                    if (this.moduleType == 'sales') {
                        this.submitSales({
                                rows
                            })
                            .then(() => {
                                this.setReportsDialog(false)
                                this.loadSalesReportsList({})
                                    .then(data => {
                                        this.setSnackbar(['success', 'Звіт завантажено!'])
                                    })
                            })
                    }
                    if (this.moduleType == 'stock') {
                        const options = this.reportData.options
                        this.submitStock({
                                rows,
                                options
                            })
                            .then(() => {
                                this.setReportsDialog(false)
                                this.loadStockReportsList({})
                                    .then(data => {
                                        
                                        this.setSnackbar(['success', 'Звіт завантажено!'])
                                    })
                            })
                    }
                })
                .catch(error => console.log(error))
        },
    },
    computed: {
        reportsDialog() {
            return this.$store.state.reports.reportsDialog
        },
        reportData() {
            const data = this.$store.state.reports.reportData

            if (data && data.options) {
                if (data.options.isMotivations) {
                    this.isMotivations = true
                }
                for (let shopId of data.options.shops) {
                    this.shopsQtyModel[shopId] = []
                    for (let item of data.recognizedData) {
                        this.shopsQtyModel[shopId].push(item.line.shopsQty[shopId])
                    }
                }
                for (let idx in data.recognizedData) {
                    if (data.recognizedData[idx].productData) {
                        if (!(data.recognizedData[idx].error && data.recognizedData[idx].error.length)) {
                            this.checked[idx] = true
                        }
                    }
                }
            }
            return data
        },
        isLoading() {
            return this.$store.state.reports.loading
        },
        shops() {
            const shopsList = this.$store.state.reports.shopsList
            const shops = {}
            if (shopsList) {
                shopsList.map(el => {
                    shops[el.shop_id] = el.name
                })
            }
            return shops
        },
        moduleType() {
            if (this.$route.name == "StockReports") {
                return 'stock'
            }
            if (this.$route.name == "SalesReports") {
                return 'sales'
            }
        },
    },
    watch: {},
}
</script>
